/* eslint-disable */
import React from 'react'

import { VariableContent, Typography, Tabs } from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'
import OneTrustPrivacyPolicy from '../components/OneTrustPrivacyPolicy'

const PrivacyPolicy = () => {
  const tabData = [
    {
      title: 'Privacy Policy',
      content: <OneTrustPrivacyPolicy />,
    },
  ]
  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: '',
          description: '',
          canonical: 'https://clearlinkinsurance.com/',
          robots: 'follow,index',
        },
        path: '/terms',
        promoCode: '169450',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/',
          siteName: 'clearlinkinsurance.com',
          alternateName: 'clearlinkinsurance.com',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'clearlinkinsurance.com | Privacy Policy',
    defaultRobots: 'follow,index',
    main: (
      <>
        <VariableContent
          backgroundColor="primary"
          style={{
            paddingLeft: 16,
            paddingRight: 16,
          }}
          alignMainContent="center"
        >
          <OneTrustPrivacyPolicy />
        </VariableContent>
      </>
    ),
    disableBreadcrumbs: false,
  }

  return <Layout {...layoutProps} />
}
export default PrivacyPolicy
